import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'cake_circle_fill_purple_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="cake_circle_fill_purple_28"><linearGradient id="cake_circle_fill_purple_28_a" gradientUnits="userSpaceOnUse" x1="-14" x2="14" y1="14" y2="42"><stop offset="0" stop-color="#937ff5" /><stop offset="1" stop-color="#735ce6" /></linearGradient><path d="M0 14C0 6.268 6.268 0 14 0s14 6.268 14 14-6.268 14-14 14S0 21.732 0 14z" fill="url(#cake_circle_fill_purple_28_a)" /><path clip-rule="evenodd" d="M18.79 6.32a.142.142 0 00-.247 0c-.212.388-.42.681-.603.938-.352.496-.607.854-.607 1.492a1.333 1.333 0 102.667 0c0-.638-.255-.996-.606-1.492-.183-.257-.391-.55-.604-.938zM6 13.417c0-.737.597-1.334 1.333-1.334h13.334c.736 0 1.333.597 1.333 1.334 0 1.305-.568 2.042-1.333 2.397l-.027.012c-.29.13-.608.207-.933.24a3.788 3.788 0 01-.374.017c-1.994 0-2.662-1.325-2.666-1.333-.003.006-.317.627-1.151 1.019a3.081 3.081 0 01-.465.174 3.6 3.6 0 01-.691.125c-.115.01-.235.015-.36.015-1.994 0-2.663-1.325-2.667-1.333-.003.006-.324.643-1.184 1.034a3.093 3.093 0 01-.431.159c-.207.06-.437.104-.692.125-.115.01-.234.015-.36.015-1.333 0-2.666-.666-2.666-2.666zm14.667 4.507c-.462.115-.918.16-1.334.16-1.093 0-1.978-.276-2.666-.659-.689.383-1.574.658-2.667.658-1.093 0-1.978-.275-2.667-.658-.688.383-1.573.658-2.666.658-.416 0-.872-.044-1.334-.159v.943c0 .746 0 1.12.146 1.405.128.25.332.455.582.583.286.145.659.145 1.406.145h9.066c.747 0 1.12 0 1.406-.145.25-.128.455-.332.582-.583.146-.285.146-.659.146-1.405zM9.21 6.32a.142.142 0 01.247 0c.213.388.42.681.603.938.352.496.607.854.607 1.492A1.333 1.333 0 018 8.75c0-.638.255-.996.607-1.492.182-.257.39-.55.603-.938zm4.914 0a.142.142 0 00-.248 0c-.212.388-.42.681-.603.938-.352.496-.606.854-.606 1.492a1.333 1.333 0 002.666 0c0-.638-.254-.996-.606-1.492-.182-.257-.39-.55-.603-.938z" fill="#fff" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28CakeCircleFillPurpleProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28CakeCircleFillPurple: FC<Icon28CakeCircleFillPurpleProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28CakeCircleFillPurple as any).mountIcon = mountIcon;

export default Icon28CakeCircleFillPurple;
