import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'gift_circle_fill_yellow_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="gift_circle_fill_yellow_28"><linearGradient id="gift_circle_fill_yellow_28_a" gradientUnits="userSpaceOnUse" x1="-14" x2="14" y1="14" y2="42"><stop offset="0" stop-color="#ffb73d" /><stop offset="1" stop-color="#ffa000" /></linearGradient><path d="M0 14C0 6.268 6.268 0 14 0s14 6.268 14 14-6.268 14-14 14S0 21.732 0 14z" fill="url(#gift_circle_fill_yellow_28_a)" /><path d="M12.609 16.5c.223 0 .304.023.385.067s.146.107.19.189.066.162.066.385v3.218c0 .223-.023.304-.067.385s-.107.146-.189.19c-.081.043-.162.066-.385.066h-2.007c-.557 0-.759-.058-.963-.167-.203-.109-.363-.269-.472-.473S9 19.955 9 19.398V17.14c0-.223.023-.304.067-.385.043-.082.107-.146.189-.19s.162-.066.385-.066zm5.75 0c.223 0 .304.023.385.067s.146.107.19.189.066.162.066.385v2.248c0 .556-.058.758-.166.961s-.268.363-.471.472c-.203.11-.405.168-.961.17l-2.15.006a.5.5 0 01-.494-.408l-.008-.09V17.14c0-.223.023-.304.067-.385s.107-.146.189-.19.162-.066.385-.066zm-5.75-5.5c.223 0 .304.023.385.067s.146.107.19.189.066.162.066.385v2.718c0 .223-.023.304-.067.385s-.107.146-.189.19c-.081.043-.162.066-.385.066H8.641c-.223 0-.304-.023-.385-.067a.454.454 0 01-.19-.189C8.024 14.663 8 14.582 8 14.36v-1.757c0-.557.058-.759.167-.963s.269-.363.472-.472c.204-.11.406-.167.963-.167zm5.789 0c.557 0 .759.058.963.167.203.109.363.269.472.472.109.204.167.406.167.963v1.757c0 .223-.023.304-.067.385s-.107.146-.189.19c-.081.043-.162.066-.385.066h-3.968c-.223 0-.304-.023-.385-.067-.082-.043-.146-.107-.19-.189s-.066-.162-.066-.385V11.64c0-.223.023-.304.067-.385s.107-.146.189-.19.162-.066.385-.066zM14 10c-4.25 0-4.75-2.75-3.75-3.75S14 5.75 14 10zm0 0c0-4.25 2.75-4.752 3.75-3.752S18.25 10 14 10z" fill="#fff" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28GiftCircleFillYellowProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28GiftCircleFillYellow: FC<Icon28GiftCircleFillYellowProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28GiftCircleFillYellow as any).mountIcon = mountIcon;

export default Icon28GiftCircleFillYellow;
