import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'message_circle_fill_green_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="message_circle_fill_green_28"><linearGradient id="message_circle_fill_green_28_a" gradientUnits="userSpaceOnUse" x1="-14" x2="14" y1="14" y2="42"><stop offset="0" stop-color="#50c750" /><stop offset="1" stop-color="#32b332" /></linearGradient><rect fill="url(#message_circle_fill_green_28_a)" height="28" rx="14" width="28" /><path clip-rule="evenodd" d="M14 8c3.314 0 6 2.406 6 5.375s-2.686 5.375-6 5.375a6.654 6.654 0 01-1.702-.22c-.583.512-1.218.968-1.895 1.36-.115.066-.25.138-.401.214l-.159.078a.6.6 0 01-.818-.763c.094-.235.169-.442.224-.619.176-.557.312-1.132.404-1.722C8.628 16.114 8 14.81 8 13.375 8 10.406 10.686 8 14 8z" fill="#fff" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28MessageCircleFillGreenProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28MessageCircleFillGreen: FC<Icon28MessageCircleFillGreenProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28MessageCircleFillGreen as any).mountIcon = mountIcon;

export default Icon28MessageCircleFillGreen;
