import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'calendar_circle_fill_red_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="calendar_circle_fill_red_28"><linearGradient id="calendar_circle_fill_red_28_a" gradientUnits="userSpaceOnUse" x1="-14" x2="14" y1="14" y2="42"><stop offset="0" stop-color="#ff5263" /><stop offset="1" stop-color="#ff3347" /></linearGradient><path d="M0 14C0 6.268 6.268 0 14 0s14 6.268 14 14-6.268 14-14 14S0 21.732 0 14z" fill="url(#calendar_circle_fill_red_28_a)" /><path clip-rule="evenodd" d="M10.616 7.1a.944.944 0 00-.46-.1h-.062a.944.944 0 00-.46.1.682.682 0 00-.284.284.944.944 0 00-.1.46v.284c-.286.066-.5.158-.715.273A2.726 2.726 0 007.4 9.535c-.262.489-.401.973-.401 2.31v4.31c0 1.336.14 1.821.4 2.31s.646.873 1.135 1.134.974.401 2.31.401h6.31c1.336 0 1.821-.14 2.31-.4s.873-.646 1.134-1.135.401-.974.401-2.31v-4.31c0-1.337-.14-1.821-.4-2.31A2.726 2.726 0 0019.464 8.4a2.764 2.764 0 00-.715-.273v-.284a.944.944 0 00-.1-.46.681.681 0 00-.284-.284.944.944 0 00-.46-.1h-.062a.944.944 0 00-.46.1.681.681 0 00-.284.284.944.944 0 00-.1.46V8l-6-.001v-.155a.944.944 0 00-.1-.46.681.681 0 00-.284-.284zm-1.033 4.65h8.834c.29 0 .394.03.5.087.106.057.19.14.246.246.057.105.087.21.087.5v4.514l-.007.126a1.151 1.151 0 01-1.145 1.025l-7.877-.018-.159-.002c-.394-.011-.559-.064-.725-.153a1.045 1.045 0 01-.434-.435c-.1-.187-.153-.373-.153-.885v-4.172l.002-.113c.007-.207.036-.297.085-.388a.591.591 0 01.246-.245c.105-.057.21-.087.5-.087zM17.04 13h-.578c-.334 0-.455.035-.577.1a.681.681 0 00-.284.284c-.065.122-.1.243-.1.577v.577c0 .335.035.456.1.578a.681.681 0 00.284.284c.122.065.243.1.578.1h.577c.334 0 .455-.035.577-.1a.681.681 0 00.284-.284c.065-.122.1-.243.1-.578v-.577c0-.334-.035-.455-.1-.577a.681.681 0 00-.284-.284c-.122-.065-.243-.1-.578-.1z" fill="#fff" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28CalendarCircleFillRedProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28CalendarCircleFillRed: FC<Icon28CalendarCircleFillRedProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28CalendarCircleFillRed as any).mountIcon = mountIcon;

export default Icon28CalendarCircleFillRed;
